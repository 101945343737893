// consumer_web_policyConfig_delTravelPolicyByBusinessType 根据业务类型删除对应出差政策
const __request = require(`./__request/__request_contentType_json`)
const consumer_web_policyConfig_delTravelPolicyByBusinessType = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/delTravelPolicyByBusinessType',
        data: data
    }
    return __request(pParameter)
}
export default consumer_web_policyConfig_delTravelPolicyByBusinessType