// consumer_web_company_checkCompanyUseCar 查询公司是否开通用车
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// https://www.apifox.cn/apidoc/shared-c91c112c-ac85-4f14-8607-3d32d61d8a80/api-24281827
const consumer_web_company_checkCompanyUseCar = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/company/checkCompanyUseCar',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_company_checkCompanyUseCar;
